import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
// styles
import clsx from 'clsx';
import styles from './hoa-managment.module.css';
import itemStyles from '../components/item/cases.module.css';
// components
import Layout from '../components/item/layout';
import Blockquote from '../components/blockquote';
import DemoVideo from '../components/demo-video';
//other
import { meta } from '../../../metaData';

function HoaManagment() {
  const data = useStaticQuery(graphql`
    query {
      reddog: file(relativePath: { eq: "reddog/reddog.png" }) {
        childImageSharp {
          fluid(maxWidth: 1720, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      img1: file(relativePath: { eq: "reddog/img-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img2: file(relativePath: { eq: "reddog/img2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img3: file(relativePath: { eq: "reddog/img3.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img4: file(relativePath: { eq: "reddog/img4.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img5: file(relativePath: { eq: "reddog/img5.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      img6: file(relativePath: { eq: "reddog/img6.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      img7: file(relativePath: { eq: "reddog/img7.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      img8: file(relativePath: { eq: "reddog/img8.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      img9: file(relativePath: { eq: "reddog/img9.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      img10: file(relativePath: { eq: "reddog/img10.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      img11: file(relativePath: { eq: "reddog/img11.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ralphTroiano: file(relativePath: { eq: "ralphTroiano.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Layout
      score={['Proptech', 'USA', '2022', 'Ongoing']}
      navBtn="#14181A"
      className={styles.header}
      contentClassName={styles.content}
      headerTitle="New Application &#10;for HOA Management &#10;Company"
      subTitle="How Brocoders helped build an application that allows homeowners and community residents to interact with their property owner’s association. As a result, the company reduced staff workload and improved the overall client experience."
      coverImgUrl={data.reddog.childImageSharp.fluid.srcWebp}
      metaData={meta.hoaManagement}
    >
      <section className={clsx(itemStyles.inner, styles.firstSection)}>
        <h2 className={styles.title}>Our client</h2>
        <p className={itemStyles.paragraph}>
          {`C.I.A.Services is a full-service company for homeowners associations based in the USA. It has around 150 clients and successfully manages more than 50,000 properties in Houston and San Antonio areas. \n\n For more than 30 years, C.I.A.Services has been providing community association management services across the United States. As the company moved towards digital operations, a major issue emerged: property owners were unable to access information from the legacy property management system without assistance from employees, adding to the workload of the company's staff. \n\n To solve this problem, C.I.A.Services decided to create an app that allows homeowners and community residents to interact with their property owner’s association. The company partnered with Brocoders to build it. \n\n We enhanced the legacy property management system to deliver new experiences for owners and residents using a progressive web application, which resulted in a reduced workload for support managers and a more positive client experience.`}
        </p>
      </section>
      <section className={clsx('section', styles.graySection)}>
        <DemoVideo
          source="CIA_Services"
          poster={data.img1.childImageSharp.fluid.srcWebp}
          className={styles.videoContainer}
          videoClassName={styles.ciaVideo}
        />
      </section>
      <div className={itemStyles.inner}>
        <h2 className={styles.title}>
          Inconvenience and inefficiency in property management: Legacy software needed to be
          updated
        </h2>
        <p className={itemStyles.paragraph}>
          {` When C.I.A.Services turned to Brocoders, they already had proprietary software to manage property owner’s associations. But this system was only available to the company’s employees. Each time a property owner needed an invoice or payment plan, they had to wait for an employee to manually deliver it by mail, email, or fax. Since the system was inconvenient and outdated, the company made a decision to update its software. \n\n Another problem was that most owners could receive their property information only via mail, which resulted in significant additional costs for the company. That’s why, by modernizing the system, C.I.A.Services also aimed to help homeowners move their property-related operations online, while reducing the company’s expenses on mail correspondence.`}
        </p>
        <p className={styles.subtitle}>
          The lack of expertise to build a consumer-facing application
        </p>
        <p className={itemStyles.paragraph}>
          In 2021, C.I.A. Services partnered with a third-party developer to enhance their existing
          system and extend it through an API. With this integration, C.I.A. Services could offer a
          better user experience for both employees and homeowners by pulling data from the backend
          via API. However, the developer company that C.I.A services collaborated with didn't have
          the expertise needed to build a consumer-facing app – so president and CEO Ralph Troiano
          reached out to Brocoders for help!
        </p>
        <p className={styles.subtitle}>Brocoders to the rescue: we had what they needed</p>
        <p className={itemStyles.paragraph}>
          {`The client approached us with an idea to build a PWA ‒ a hybrid web application that looks and operates like a mobile app, with a close-to-native experience. It is fast, reliable, and secure. Furthermore, users can launch a progressive web app on any device without having to download it from the dedicated app store. \n\n Our team fully supported this ideasince developing a PWA was a perfect solution to implement the required functionality while satisfying the client’s requirements.`}
        </p>
        <div className={styles.images}>
          <Img fluid={data.img2.childImageSharp.fluid} className={styles.img} alt="" />
          <Img fluid={data.img3.childImageSharp.fluid} className={styles.img} alt="" />
        </div>
        <h2 className={itemStyles.title}>
          Building a secure, efficient, and intuitive Progressive Web App
        </h2>
        <p className={itemStyles.paragraph}>
          To bring new experiences to property owners and residents, and ease the workload on
          employees, Brocoders developed an app with an intuitive design available on desktop and
          mobile devices. To implement features following the app specifications, we needed API
          data, so we worked closely with our client's backend developer. Additionally, we added new
          user roles with different access rights to protect confidential information.
        </p>
        <p className={styles.subtitle}>Appealing and straightforward UI/UX design</p>
        <p className={itemStyles.paragraph}>
          To build a customer-facing app, our team started the project by working on the solution’s
          interface. We created designs, showed them to the client, and performed user testing with
          the client’s representative. Based on the user’s feedback, we made decisions regarding
          what changes should be made to meet the client’s requirements, and iteratively agreed on
          the final version. To make it easier to access community information and property data,
          Brocoders created a modern and appealing interface, easy to use and navigate. For
          instance, when logging in to their account, a property owner or resident can register for
          a community event like a garage sale, vote for candidates to the Board of Directors, read
          current community news, and view planned events in the calendar. The app also gives users
          access to community information like facilities and services, plus property data including
          financial information, compliance issues, and improvement applications.
        </p>

        <div className={styles.images}>
          <Img fluid={data.img4.childImageSharp.fluid} className={styles.img} alt="" />
          <Img fluid={data.img5.childImageSharp.fluid} className={styles.img} alt="" />
          <Img fluid={data.img6.childImageSharp.fluid} className={styles.img} alt="" />
          <Img fluid={data.img7.childImageSharp.fluid} className={styles.img} alt="" />
          <Img fluid={data.img8.childImageSharp.fluid} className={styles.img} alt="" />
          <Img fluid={data.img9.childImageSharp.fluid} className={styles.img} alt="" />
        </div>
        <p className={clsx(styles.subtitle, styles.subtitleMagrin)}>
          API evaluation and improvements
        </p>
        <p className={itemStyles.paragraph}>
          When the design was approved, we proceeded to the next stage. Since we were building a
          PWA, we had to make sure that the website’s API endpoints supported our requirements. We
          detected issues with API endpoints that made it impossible to build specific functionality
          on our part. For instance, news articles on the website didn’t have individual identifiers
          so we could filter them and show a homeowner only news pieces related to his or her
          association. That’s why we contacted the developer and asked to make some improvements to
          the API.
        </p>

        <p className={styles.subtitle}>Varying access rights to secure sensitive information</p>
        <p className={itemStyles.paragraph}>
          To protect users’ information and reduce security risks, we created different user roles
          with varying access rights. Owners have access to their account information, while
          multi-lot owners can view data from all their accounts. Apart from access rights as an
          owner, board members can access some confidential information for their community and
          employees can view all information for all client communities.
        </p>

        <p className={styles.subtitle}>Deploying the app on Microsoft Azure</p>
        <p className={itemStyles.paragraph}>
          For the client's system to run on Microsoft Azure, we had to deploy the app on that
          platform. First, we created a staging environment on AWS to ensure the software satisfied
          project requirements and worked as expected. Then we worked closely with the third-party
          developer to resolve issues on Microsoft Azure and released the application.
        </p>
      </div>
      <BackgroundImage
        fluid={data.img10.childImageSharp.fluid}
        className={styles.bg}
        style={{
          backgroundPosition: 'top left 32%',
        }}
      />

      <div className={itemStyles.inner}>
        <h2 className={styles.title}>
          Results: Better experiences for users, higher efficiency for company employees, reduced
          costs for the business
        </h2>
        <p className={itemStyles.paragraph}>
          By helping C.I.A.Services create a convenient app, we improved customer service and
          reduced employee workload. Users can access information without involving employees and
          the company can handle more clients without hiring new staff – all while saving money on
          app development.
        </p>

        <p className={styles.subtitle}>Improved customer service</p>
        <p className={itemStyles.paragraph}>
          Designing an appealing and convenient application, we helped C.I.A.Services improve its
          customer service, which led to more clients using the app.
        </p>

        <p className={styles.subtitle}>Decreased workload for support managers</p>
        <p className={itemStyles.paragraph}>
          The solution we developed allows users to get the information they need without involving
          employees or company managers. The software decreases time spent on manual tasks, such as
          sending emails to owners and residents or calling them by phone and does not overburden
          the company’s employees. This way, C.I.A.Services can smoothly handle the growing number
          of new users and grow their client portfolio without hiring new employees and increasing
          expenses.
        </p>

        <p className={styles.subtitle}>Cost-effective development approach</p>
        <p className={itemStyles.paragraph}>
          In addition to delivering new experiences to property owners and decreasing employees'
          workload, Brocoders extended the capabilities of their system's API. What's more, as we
          chose to build a progressive web app (PWA), our client got a solution that reaches users
          across various platforms and costs less than native development.
        </p>
      </div>
      <section className="section">
        <Blockquote
          img={data.ralphTroiano.childImageSharp.fluid}
          blockquoteClassName={styles.blockquote}
          innerClassName={styles.blockquoteInner}
          text={
            '“I am very excited and thankful for the accomplished work. It was great to work with a so professional and well-organized team. All tasks were delivered on time and were well-tested by QA specialists and our users. Reddog backend isn’t ready for all functionality we need, so after finishing it, we return to work on this project with Brocoders and release the full version to production.”'
          }
          author={
            <React.Fragment>
              <strong>Ralph Troiano</strong> <br />
              President &amp; CEO of{' '}
              <a href="https://www.ciaservices.com" target="_blank" className={styles.red}>
                C.I.A.Services
              </a>
            </React.Fragment>
          }
        />
      </section>
      <Img fluid={data.img11.childImageSharp.fluid} className={styles.handImg} alt="" />
    </Layout>
  );
}

export default HoaManagment;
